<template>
   <div id="dialog-header">
    <div class="close-icon" >
      <img @click="$emit('close')" src="../../assets/icon/close-dialog-icon.svg" alt="">
    </div>
    <div class="dialog-title">
        {{title}}
    </div>
    <hr>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required:true
    }
  }
}
</script>

<style lang="scss" scoped>
hr{
  border: 1px solid #D8E2E4;
}
.close-icon{
  text-align:right;
  position: absolute;
  right:5%;
  top:3%;
  cursor:pointer;
}
 .dialog-title {
    margin-top: 20px!important;
    text-align: left !important;
    margin-bottom: 20px!important;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #1c4a5e;
  }
</style>